import Vue from 'vue'
import Vuex from 'vuex'
import Moment from 'vue-moment'
import Toasted from 'vue-toasted'
import VueCookies from 'vue-cookies'
import Router from './router'
import App from './App.vue'
import Store from './store/index.js'
import * as Sentry from "@sentry/vue";
import JsonExcel from 'vue-json-excel'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { Swatches } from 'vue-color'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Vuex)
Vue.use(Moment)
Vue.use(Toasted, {
  position: 'top-center',
  fitToScreen: true,
  keepOnHover: true,
  type: 'info'
})
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Element, { locale })
Vue.component('downloadExcel', JsonExcel)
Vue.component('colorPicker', Swatches)

Vue.$cookies.config('8h')

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router: Router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router: Router,
  store: Store(Vuex),
  render: (h) => h(App)
}).$mount('#app')
