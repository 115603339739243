<template>
  <b-container
    fluid
    class="welcome-user"
    :class="hasError ? 'error-banner' : 'ok-banner'"
  >
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!mobileView">
      <div
        class="col-sm-12 text-left header-msg"
        v-if="IsAuthenticated && !hasError"
      >
        <div class="row">
          <div class="col-lg-10 text-left">
            <h4>
              <span v-if="UserData.details.role != 'not_specified'">
                <strong>{{
                  UserData.details.firstName + ' ' + UserData.details.lastName
                }}</strong>
                &nbsp;<b-badge
                  :variant="GetTotalNewNotifications() ? 'danger' : 'info'"
                  v-b-tooltip.hover.right="{
                    variant: GetTotalNewNotifications()
                      ? 'primary'
                      : 'secondary'
                  }"
                  :title="
                    GetTotalNewNotifications()
                      ? GetTotalNewNotifications() + ' new notification(s)'
                      : 'There isn\'t any notification!'
                  "
                  class="notification-badge"
                  id="notif-btn"
                  >{{ GetTotalNewNotifications() }}</b-badge
                >
                <b-popover
                  v-if="GetTotalNewNotifications()"
                  target="notif-btn"
                  triggers="hover"
                  id="notif-popover"
                  placement="bottomright"
                  title="Notifications"
                >
                  <b-row
                    no-gutters
                    class="justify-content-md-center notif-item-row"
                    v-for="notif in GetNotications()"
                    :key="notif.uid"
                    v-b-modal.notif-modal
                    @click="GetNoticationsInfo(notif.uid)"
                  >
                    <b-col
                      col
                      lg="2"
                      class="owner"
                      v-b-tooltip.hover.left="{ variant: 'info' }"
                      :title="GetNotificationOwner(notif.owner, false)"
                      >{{ GetNotificationOwner(notif.owner) }}</b-col
                    >
                    <b-col col lg="6" class="subject">{{
                      notif.subject
                    }}</b-col>
                    <b-col
                      col
                      lg="4"
                      class="date"
                      v-b-tooltip.hover.right="{ variant: 'info' }"
                      :title="notif.date"
                    >
                      <b-icon icon="clock" aria-hidden="true"></b-icon>&nbsp;{{
                        notif.date
                      }}</b-col
                    >
                  </b-row>
                </b-popover>
                <b-modal
                  id="notif-modal"
                  size="lg"
                  header-bg-variant="info"
                  headerTextVariant="light"
                  centered
                  title="Notification"
                >
                  <template #modal-header>
                    <b-row no-gutters class="justify-content-md-center">
                      <b-col col lg="12"
                        ><h5>{{ notifications.modal.data.subject }}</h5></b-col
                      >
                    </b-row>
                    <b-row no-gutters class="justify-content-md-center">
                      <b-col col lg="12"
                        ><span class="notif-date">{{
                          notifications.modal.data.date
                        }}</span></b-col
                      >
                    </b-row>
                  </template>
                  <template #modal-footer="{ close }">
                    <b-button size="sm" variant="secondary" @click="close()"
                      >Close</b-button
                    >
                    <b-button
                      size="sm"
                      variant="outline-info"
                      @click="
                        markNotifAsRead(notifications.modal.data.uid, close)
                      "
                    >
                      Mark as read
                    </b-button>
                  </template>
                  <p v-html="notifications.modal.data.body"></p>
                </b-modal>
              </span>
              <strong v-else>Welcome!</strong>
            </h4>
          </div>
          <div class="col-lg-2 text-right">
            <b-badge
              v-if="UserData.details.role != 'not_specified'"
              variant="primary"
              style="text-transform: uppercase"
              >{{ UserData.details.role }}</b-badge
            >
          </div>
        </div>
      </div>
      <div class="col-sm-12 header-msg text-left" v-else>
        <h4>
          <strong>Your current session has expired. Please wait...</strong>
        </h4>
      </div>
    </div>
    <div class="row" v-if="mobileView">
      <div
        class="col-sm-12 text-left header-msg"
        v-if="IsAuthenticated && !hasError"
      >
        <div class="row">
          <div class="col-lg-12 text-left">
            <span v-if="UserData.details.role != 'not_specified'">
              <strong>{{
                UserData.details.firstName + ' ' + UserData.details.lastName
              }}</strong>
              &nbsp;<b-badge
                :variant="GetTotalNewNotifications() ? 'danger' : 'info'"
                v-b-tooltip.hover.right="{
                  variant: GetTotalNewNotifications() ? 'primary' : 'secondary'
                }"
                :title="
                  GetTotalNewNotifications()
                    ? GetTotalNewNotifications() + ' new notification(s)'
                    : 'There isn\'t any notification!'
                "
                class="notification-badge"
                id="notif-btn"
                >{{ GetTotalNewNotifications() }}</b-badge
              >
              <b-popover
                v-if="GetTotalNewNotifications()"
                target="notif-btn"
                triggers="hover"
                id="notif-popover"
                placement="bottomright"
                title="Notifications"
              >
                <b-row
                  no-gutters
                  class="justify-content-md-center notif-item-row"
                  v-for="notif in GetNotications()"
                  :key="notif.uid"
                  v-b-modal.notif-modal
                  @click="GetNoticationsInfo(notif.uid)"
                >
                  <b-col
                    col
                    lg="2"
                    class="owner"
                    v-b-tooltip.hover.left="{ variant: 'info' }"
                    :title="GetNotificationOwner(notif.owner, false)"
                    >{{ GetNotificationOwner(notif.owner) }}</b-col
                  >
                  <b-col col lg="6" class="subject">{{ notif.subject }}</b-col>
                  <b-col
                    col
                    lg="4"
                    class="date"
                    v-b-tooltip.hover.right="{ variant: 'info' }"
                    :title="notif.date"
                  >
                    <b-icon icon="clock" aria-hidden="true"></b-icon>&nbsp;{{
                      notif.date
                    }}</b-col
                  >
                </b-row>
              </b-popover>
              <b-modal
                id="notif-modal"
                size="lg"
                header-bg-variant="info"
                headerTextVariant="light"
                centered
                title="Notification"
              >
                <template #modal-header>
                  <b-row no-gutters class="justify-content-md-center">
                    <b-col col lg="12"
                      ><h5>{{ notifications.modal.data.subject }}</h5></b-col
                    >
                  </b-row>
                  <b-row no-gutters class="justify-content-md-center">
                    <b-col col lg="12"
                      ><span class="notif-date">{{
                        notifications.modal.data.date
                      }}</span></b-col
                    >
                  </b-row>
                </template>
                <p v-html="notifications.modal.data.body"></p>
                <template #modal-footer="{ close }">
                  <b-button size="sm" variant="secondary" @click="close()"
                    >Close</b-button
                  >
                  <b-button
                    size="sm"
                    variant="outline-info"
                    @click="
                      markNotifAsRead(notifications.modal.data.uid, close)
                    "
                  >
                    Mark as read
                  </b-button>
                </template>
              </b-modal>
            </span>
            <strong v-else>Welcome!</strong>
          </div>
        </div>
      </div>
      <div class="col-sm-12 header-msg text-left" v-else>
        <strong
          >Your current session has expired. Please refresh the page and sign in
          again.</strong
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import Services from '../services/main.service.vue'

export default {
  name: 'WelcomeUser',
  props: {
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hasError: false,
      notifications: {
        modal: {
          data: {}
        }
      }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    UserData() {
      return this.Store.userAuth
    },
    IsAuthenticated() {
      return this.UserData.authenticated
    },
    UserNotifications() {
      return this.Store.userNotifications
    }
  },
  methods: {
    GetTotalNewNotifications() {
      // console.log(this.UserNotifications)
      // return 0
      return this.UserNotifications?.total ?? 0
    },
    GetNotications() {
      if (this.GetTotalNewNotifications()) {
        return this.UserNotifications?.list ?? []
      }
      return []
    },
    GetNotificationOwner(owner, initials = true) {
      if (initials) {
        return (
          owner.firstName.charAt(0).toUpperCase() +
          owner.lastName.charAt(0).toUpperCase()
        )
      }
      return owner.firstName + ' ' + owner.lastName
    },
    fetchUser() {
      Services.Users.fetchCurrentUser()
        .then(({ status, data }) => {
          if (status !== 200) {
            this.$root.$emit('user-signed-out')
          }
          this.$store.commit(
            'updateNotifications',
            data?.content?.notifications ?? {}
          )
          // this.$root.$emit(`parent_${tabId}_selected`)
        })
        .catch(() => {
          this.$root.$emit('user-signed-out')
        })
    },
    async GetNoticationsInfo(notifUID) {
      this.notifications.modal.data = {}
      const { status, data } = await Services.Users.getNotificationInfo(
        notifUID
      )
      if (status === 200) {
        data.content.owner = this.GetNotificationOwner(
          data.content.owner,
          false
        )
        this.notifications.modal.data = data.content
      }
    },
    async markNotifAsRead(notifUID, cb = () => {}) {
      const { status } = await Services.Users.markNotificationAsRead(notifUID)
      if (status === 200) {
        this.$bvToast.toast('Notification is marked as read.', {
          title: 'Success',
          variant: 'success',
          solid: true
        })
      }
      this.fetchUser()
      cb()
    }
  },
  watch: {
    IsAuthenticated() {
      this.hasError = !this.IsAuthenticated
    }
  }
}
</script>

<style scoped>
.welcome-user {
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-right-width: 0px;
}
.welcome-user.error-banner {
  background-color: #bc0707 !important;
}
.welcome-user.ok-banner {
  background-color: #23b1f3 !important;
}
.lizard-form-empty-col {
  min-height: 10px !important;
}
.header-msg {
  color: #f3f3f3;
  /* color: #0773BC; */
  font-weight: bold !important;
}
.header-msg h4 {
  margin: 0;
}
.notification-badge {
  color: #eee;
  font-size: 8pt;
  font-weight: bold;
  width: 16px;
  height: 16px;
  line-height: 18px;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}

.notification-badge:active {
  color: #999;
  font-size: 8pt;
  background-color: #333;
}
.notif-item-row {
  margin: 2px 0;
  padding: 0;
  text-align: left;
  font-size: 10pt;
  font-weight: 600;
  color: #666;
}

.notif-item-row:hover {
  color: #333;
}

.notif-item-row .owner {
  background-color: #999;
  color: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 2px 0;
  font-size: 8pt;
  font-weight: bold;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notif-item-row .subject {
  border-radius: 2px;
  margin: 0;
  padding: 2px 6px;
  font-size: 8pt;
  font-weight: 600;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}

.notif-item-row .date {
  color: #ccc;
  border-radius: 2px;
  margin: 0;
  padding: 2px 6px;
  font-size: 8pt;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.modal-body {
  font-size: 8pt !important;
  font-weight: 600;
  color: #666;
}
.notif-date {
  text-transform: uppercase;
  font-size: 10pt;
}
</style>
<style>
#notif-popover {
  min-width: 20vw !important;
}
#notif-popover .popover-header {
  font-size: 7pt !important;
  font-weight: bold;
  color: #666;
}
</style>
