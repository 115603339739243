<script>
import Vue from 'vue'
import axios from 'axios'

let NOW = null

const exchangeCredentials = [
  {
    baseUrl: 'https://v6.exchangerate-api.com/v6/',
    params: {
      app_id: 'f8b0147c597480e0f285a9dd',
      base: 'EUR'
    }
  },
  {
    baseUrl: 'https://openexchangerates.org',
    params: {
      app_id: '2ad683372a4b4ab7b9586173d873c5d4',
      base: 'EUR',
      symbols: 'GBP,USD'
    }
  },
  {
    baseUrl: 'https://data.fixer.io',
    params: {
      access_key: '38927115a665ccf8b68f805ad080f4eb',
      base: 'EUR',
      symbols: 'GBP,USD'
    }
  }
]

const RESPONSECACHE = {}

const RequestDefaults = function (baseUrl) {
  return axios.create({
    baseURL: baseUrl,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const Services = function () {
  NOW = new Date()
  NOW = `${NOW.getFullYear()}-${NOW.getMonth() + 1}-${NOW.getDate()}`
  this.Request = RequestDefaults(process.env.VUE_APP_API_BASE_URL)
  this.Request.interceptors.response.use((response) => {
    if (response.status === 200) {
      return Promise.resolve(response)
    }
    console.log('FUCK (2)?! STATUS SUCKS!', response.status)
    return Promise.reject(response)
  })
  const {
    Request,
    UseV1,
    UseV2,
    SetApiToken,
    SetCustomHeader,
    GetEURBaseRates,
    AddHoliday,
    AddHolidayByAdmin,
    GetHolidayTypeOptions,
    GetHolidays,
    GetHolidayList,
    GetHolidayListFromMonday,
    GetHolidayAllowences,
    GetHolidayAllowencesByUser,
    AddTimelog,
    GetTimeLogs,
    GetTimeLogsForAdmin,
    RemoveTimelog,
    RemoveHoliday,
    UpdateHoliday,
    GetBankHolidays,
    GetClientProjectsReports,
    GetClientProjectsReportsOLD,
    AddExpense,
    GetExpenses,
    GetExpenseReports,
    GetExpenseExcelReports,
    UpdateExpense,
    UpdateExpenseByAdmin,
    RemoveExpense,
    GetExpensesByProject,
    UploadExpenseBill,
    RemoveExpenseBill,
    DownloadExpenseBill,
    GetExpenseCategories,
    GetUserRates,
    AddNewUserRate,
    UpdateNewUserRate,
    DeleteUserRate,
    GetStaffTimelog,
    SaveStaffingAssignment,
    RemoveStaffingAssignment,
    GetStaffingAssignments,
    GetStaffingAssignmentsForAdmin,
    GetStaffingAssignmentsByUser,
    GetTimeLogReports,
    UploadFilesBeta,
    GetOneNotePagesBeta,
    GetOneNotePageDataBeta
  } = this
  return {
    Request,
    UseV1,
    UseV2,
    SetApiToken,
    SetCustomHeader,
    GetEURBaseRates,
    AddTimelog,
    AddHoliday,
    AddHolidayByAdmin,
    GetTimeLogs,
    GetTimeLogsForAdmin,
    GetHolidayTypeOptions,
    GetHolidays,
    GetHolidayList,
    GetHolidayListFromMonday,
    GetHolidayAllowences,
    GetHolidayAllowencesByUser,
    RemoveTimelog,
    RemoveHoliday,
    UpdateHoliday,
    GetClientProjectsReports,
    GetClientProjectsReportsOLD,
    UploadExpenseBill,
    RemoveExpenseBill,
    DownloadExpenseBill,
    GetExpenseCategories,
    AddExpense,
    GetExpenses,
    GetExpenseReports,
    GetExpenseExcelReports,
    UpdateExpense,
    UpdateExpenseByAdmin,
    RemoveExpense,
    GetExpensesByProject,
    GetUserRates,
    AddNewUserRate,
    UpdateNewUserRate,
    DeleteUserRate,
    GetStaffTimelog,
    SaveStaffingAssignment,
    RemoveStaffingAssignment,
    GetStaffingAssignments,
    GetStaffingAssignmentsForAdmin,
    GetStaffingAssignmentsByUser,
    GetBankHolidays,
    GetTimeLogReports,
    UploadFilesBeta,
    GetOneNotePagesBeta,
    GetOneNotePageDataBeta
  }
}

Services.prototype.UseV1 = function () {
  this.Request.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
}

Services.prototype.UseV2 = function () {
  this.Request.defaults.baseURL = process.env.VUE_APP_API_BASE_URL_V2
}

Services.prototype.SetApiToken = function () {
  this.Request.defaults.headers['Authorization'] =
    'Bearer ' + Vue.$cookies.get('__api-token')
}

Services.prototype.SetCustomHeader = function (key, value) {
  this.Request.defaults.headers[key] = value
}

Services.prototype.GetEURBaseRates = function (credentialsId = 0) {
  const credentials = exchangeCredentials[credentialsId]
  if (typeof credentials === 'undefined') {
    throw new Error('exchange rate service is down')
  }
  // console.log('fetching exchange rates from: %s', credentials.baseUrl)
  const Request = RequestDefaults(credentials.baseUrl)
  return new Promise((resolve) => {
    Request({
      url: `/${credentials.params.app_id}/latest/${credentials.params.base}`,
      method: 'GET'
    })
      .then((result) => resolve(result))
      .catch((err) => {
        console.log('failed:', err)
        // credentialsId++
        // return this.GetEURBaseRates(credentialsId)
      })
  })
}

Services.prototype.GetTimeLogs = async function ({
  type = null,
  dates = null,
  project = null
}) {
  this.UseV2()
  this.SetApiToken()
  const queryBuilder = []
  if (dates) {
    queryBuilder.push(`fromDate=${dates?.from}`)
    queryBuilder.push(`toDate=${dates?.to}`)
  }
  if (project) {
    queryBuilder.push(`projectUid=${project}`)
  }
  return await this.Request({
    url:
      '/timelogs' +
      (type ? '/' + type : '') +
      (queryBuilder.length ? '?' + queryBuilder.join('&') : '')
  })
}

Services.prototype.GetTimeLogsForAdmin = async function (fromDate, toDate) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: `/admin/timelogs/${fromDate}/${toDate}`
  })
}

Services.prototype.GetHolidays = async function (type = null, opts = {}) {
  try {
    this.UseV2()
    this.SetApiToken()

    const queryString = Object.entries(opts)
      .filter(([key, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const url = `/holidays/list${type ? `/${type}` : ''}${
      queryString ? `?${queryString}` : ''
    }`
    return await this.Request({ url })
  } catch (error) {
    console.error('Error fetching holidays:', error)
    throw error
  }
}

Services.prototype.GetHolidayTypeOptions = async function ({
  userUid = null,
  year = new Date().getFullYear()
}) {
  this.UseV2()
  this.SetApiToken()
  const queryString = Object.entries({ userUid, year })
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  const url = `/holidays/types${queryString ? `?${queryString}` : ''}`
  return await this.Request({ url })
}

Services.prototype.GetHolidayAllowences = async function (opts = {}) {
  this.UseV2()
  this.SetApiToken()

  const queryString = Object.entries(opts)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  const url = `/holidays/allowences${queryString ? `?${queryString}` : ''}`
  return await this.Request({ url })
}

Services.prototype.GetHolidayAllowencesByUser = async function (uid) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/holidays/allowences/user/' + uid
  })
}

Services.prototype.UpdateHoliday = async function (uid, { status, comment }) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/holidays/uid/' + uid,
    method: 'patch',
    data: {
      status,
      comment
    }
  })
}

Services.prototype.AddTimelog = async function (data = {}) {
  this.UseV2()
  this.SetApiToken()
  let { date, hours, projectUid } = data
  return await this.Request({
    url: '/timelogs',
    method: 'post',
    data: {
      projectUid,
      hours,
      date
    }
  })
}

Services.prototype.RemoveTimelog = async function ({ projectUid, date }) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/timelogs/' + projectUid + '?date=' + date,
    method: 'delete'
  })
}

Services.prototype.AddHoliday = async function (data = {}) {
  this.UseV2()
  this.SetApiToken()
  let {
    dates,
    holidayHalfDayMeridiem: meridiem = 'AM',
    duration: totalDays,
    holidayType: type,
    notes: comment
  } = data
  return await this.Request({
    url: '/holidays',
    method: 'post',
    data: {
      type,
      startDate: dates[0],
      endDate: dates[1] || dates[0],
      meridiem,
      totalDays,
      comment
    }
  })
}

Services.prototype.AddHolidayByAdmin = async function (data = {}) {
  this.UseV2()
  this.SetApiToken()
  let {
    user: owner,
    dates,
    holidayHalfDayMeridiem: meridiem = 'AM',
    totalDays,
    type,
    notes: comment
  } = data
  return await this.Request({
    url: '/admin/holidays',
    method: 'post',
    data: {
      owner,
      type,
      startDate: dates[0],
      endDate: dates[1] || dates[0],
      meridiem,
      totalDays,
      comment
    }
  })
}

Services.prototype.GetHolidayListFromMonday = async function () {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/monday/absence-tracker'
  })
}

Services.prototype.GetHolidayList = async function ({
  userUid,
  datesType,
  dates
}) {
  this.UseV2()
  this.SetApiToken()
  let queryBuilder = []
  if (userUid) {
    queryBuilder.push(`userUid=${userUid}`)
  }
  if (datesType) {
    queryBuilder.push(`datesType=${datesType}`)
  }
  if (dates) {
    queryBuilder.push(`dates=${dates}`)
  }
  return await this.Request({
    url:
      '/admin/holidays/list' +
      (queryBuilder.length ? '?' + queryBuilder.join('&') : '')
  })
}

Services.prototype.RemoveHoliday = async function (id = null) {
  if (!id) {
    return
  }
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/holidays/' + id,
    method: 'delete'
  })
}

Services.prototype.AddExpense = async function (data = {}) {
  this.UseV2()
  this.SetApiToken()
  let {
    AttachmentObjectKey,
    Description: notes,
    ProjectCode,
    category,
    amount,
    vat,
    currency,
    approvalmanager,
    date,
    expenseProjType
  } = data
  return await this.Request({
    url: '/expenses',
    method: 'post',
    data: {
      projectUid:
        expenseProjType === 'bd'
          ? ProjectCode.split('_')[1]
          : ProjectCode.split(':')[1],
      managerUid: approvalmanager,
      category,
      currency,
      date,
      amount: parseFloat(amount),
      vat: parseFloat(vat),
      notes,
      attachmentKeys: AttachmentObjectKey
    }
  })
}

Services.prototype.GetExpenses = async function (query = {}, admin = false) {
  this.UseV2()
  this.SetApiToken()
  let queryBuilder = []
  for (const key in query) {
    if (query[key]) {
      queryBuilder.push(`${key}=${query[key]}`)
    }
  }
  return await this.Request({
    url:
      (admin ? '/admin/expenses' : '/expenses') + '?' + queryBuilder.join('&')
  })
}

Services.prototype.GetExpenseReports = async function (query = {}) {
  this.UseV2()
  this.SetApiToken()
  let queryBuilder = []
  for (const key in query) {
    if (query[key]) {
      queryBuilder.push(`${key}=${query[key]}`)
    }
  }
  // if (projectUid) {
  //   queryBuilder.push(`projectUid=${projectUid}`)
  // }
  // if (owner) {
  //   queryBuilder.push(`owner=${owner}`)
  // }
  if (queryBuilder.length) {
    queryBuilder = '?' + queryBuilder.join('&')
  } else {
    queryBuilder = ''
  }
  return await this.Request({
    url: '/admin/expenses/reports' + queryBuilder
  })
}

Services.prototype.GetExpenseExcelReports = async function (query = {}) {
  this.UseV2()
  this.SetApiToken()
  let queryBuilder = []
  for (const key in query) {
    if (query[key]) {
      queryBuilder.push(`${key}=${query[key]}`)
    }
  }
  // if (projectUid) {
  //   queryBuilder.push(`projectUid=${projectUid}`)
  // }
  // if (owner) {
  //   queryBuilder.push(`owner=${owner}`)
  // }
  if (queryBuilder.length) {
    queryBuilder = '?' + queryBuilder.join('&')
  } else {
    queryBuilder = ''
  }
  return await this.Request({
    url: '/admin/expenses/excel' + queryBuilder
  })
}

// V1
Services.prototype.GetExpensesByProject = async function ({ code, from, to }) {
  this.UseV1()
  this.SetApiToken()
  return await this.Request({
    url: `/expense/projects/${code}/reports/clients-projects/${from}/${to}`
  })
}

Services.prototype.UpdateExpense = async function (uid = null, data) {
  if (!uid) {
    return
  }
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/expenses/uid/' + uid,
    method: 'patch',
    data
  })
}

Services.prototype.UpdateExpenseByAdmin = async function (uid = null, data) {
  if (!uid) {
    return
  }
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/expenses/uid/' + uid,
    method: 'patch',
    data
  })
}

Services.prototype.RemoveExpense = async function (uid = null) {
  if (!uid) {
    return
  }
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/expenses/uid/' + uid,
    method: 'delete'
  })
}

Services.prototype.GetClientProjectsReports = async function ({
  projectUid,
  userUid,
  from = '2019-08-03', // Aref moved to UK on this day!
  to = NOW
}) {
  this.UseV2()
  this.SetApiToken()
  let queryBuilder = []
  if (projectUid) {
    queryBuilder.push(`project=${projectUid}`)
  }
  if (userUid) {
    queryBuilder.push(`user=${userUid}`)
  }
  if (queryBuilder.length) {
    queryBuilder = '?' + queryBuilder.join('&')
  } else {
    queryBuilder = ''
  }
  return await this.Request(`/admin/timelogs/${from}/${to}${queryBuilder}`)
}

// V1
Services.prototype.GetClientProjectsReportsOLD = async function ({
  code,
  from,
  to
}) {
  this.UseV1()
  this.SetApiToken()
  return await this.Request(
    `/timelog/projects/${code}/reports/clients-projects/${from}/${to}`
  )
}

Services.prototype.UploadExpenseBill = async function (file) {
  this.UseV2()
  this.SetApiToken()
  this.SetCustomHeader('Content-Type', 'multipart/form-data')
  const formData = new FormData()
  formData.append('file', file, '_' + encodeURI(file.name.toLowerCase()))
  return await this.Request({
    url: '/expenses/receipt',
    method: 'post',
    data: formData
  })
}

Services.prototype.RemoveExpenseBill = async function (fileName) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: `/expenses/receipt/${fileName}`,
    method: 'delete'
  })
}

Services.prototype.DownloadExpenseBill = function (fileName) {
  this.UseV2()
  this.SetApiToken()
  this.Request.get(`/expenses/receipt/${fileName}`, {
    responseType: 'arraybuffer'
  }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  })
}

Services.prototype.GetExpenseCategories = async function (settings = {}) {
  if (RESPONSECACHE['EXPENSECATEGORIES'] && settings.cache) {
    return RESPONSECACHE['EXPENSECATEGORIES']
  }
  this.UseV2()
  this.SetApiToken()
  const result = await this.Request({
    url: '/fees/categories',
    method: 'get',
    data: {}
  })
  if (result.status === 200) {
    RESPONSECACHE['EXPENSECATEGORIES'] = result
  }
  return result
}

Services.prototype.GetUserRates = async function ({
  userUid = null,
  startDate = null,
  endDate = null
}) {
  this.UseV2()
  this.SetApiToken()
  let url = `/admin/rates/user/${userUid}`
  if (startDate && endDate) {
    url += `/${startDate}/${endDate}`
  }
  return await this.Request({
    url
  })
}

Services.prototype.AddNewUserRate = async function (userUid, data = {}) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: `/admin/rates/user/${userUid}`,
    method: 'post',
    data
  })
}

Services.prototype.UpdateNewUserRate = async function (rateUid, data = {}) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: `/admin/rates/${rateUid}`,
    method: 'patch',
    data
  })
}

Services.prototype.DeleteUserRate = async function (rateUid) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: `/admin/rates/${rateUid}`,
    method: 'delete'
  })
}
// V1
Services.prototype.GetStaffTimelog = async function (startDate, endDate) {
  this.UseV1()
  this.SetApiToken()
  return await this.Request(`/timelog/staff/from/${startDate}/to/${endDate}`)
}

Services.prototype.SaveStaffingAssignment = async function ({
  userUid,
  date,
  projectUid
}) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/staffing/assignments',
    method: 'post',
    data: {
      user: userUid,
      date,
      project: projectUid
    }
  })
}

Services.prototype.RemoveStaffingAssignment = async function ({
  assignmentUid
}) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/staffing/assignments/uid/' + assignmentUid,
    method: 'delete'
  })
}

Services.prototype.GetStaffingAssignmentsForAdmin = async function ({
  fromDate,
  weeks = 2,
  ownerRoles = []
}) {
  const queryBuilder = ['fromDate=' + fromDate, 'weeks=' + weeks]
  if (ownerRoles.length) {
    queryBuilder.push(`ownerRoles=${ownerRoles.join(',')}`)
  }
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/admin/staffing/assignments/list/?' + queryBuilder.join('&'),
    method: 'get'
  })
}

Services.prototype.GetStaffingAssignments = async function ({
  fromDate,
  weeks = 2,
  ownerRoles = []
}) {
  try {
    const queryBuilder = ['fromDate=' + fromDate, 'weeks=' + weeks]
    if (ownerRoles.length) {
      queryBuilder.push(`ownerRoles=${ownerRoles.join(',')}`)
    }
    this.UseV2()
    this.SetApiToken()
    return await this.Request({
      url: '/staffing/assignments/list/?' + queryBuilder.join('&'),
      method: 'get'
    })
  } catch (Exception) {
    console.log('FUCK?>????')
  }
}

Services.prototype.GetStaffingAssignmentsByUser = async function ({
  fromDate,
  weeks = 2
}) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: '/staffing/assignments/?fromDate=' + fromDate + '&weeks=' + weeks,
    method: 'get'
  })
}

Services.prototype.GetBankHolidays = async function (useCache = true) {
  if (RESPONSECACHE['BANKHOLIDAYS'] && useCache) {
    return {
      status: 200,
      data: {
        content: RESPONSECACHE['BANKHOLIDAYS']
      }
    }
  }
  this.UseV2()
  this.SetApiToken()
  const { status = 500, data = {} } = await this.Request({
    url: '/bank-holidays',
    method: 'get'
  })
  if (status === 200) {
    RESPONSECACHE['BANKHOLIDAYS'] = data.content
  }
  return { status, data }
}

Services.prototype.GetTimeLogReports = async function (
  filters = {},
  reports = []
) {
  if (reports.length === 0) {
    return
  }
  this.UseV2()
  this.SetApiToken()
  let queryBuilder = []
  for (const key in filters) {
    if (filters[key]) {
      queryBuilder.push(`${key}=${filters[key] || null}`)
    }
  }
  queryBuilder.push(`reports=${reports.join(',')}`)
  queryBuilder = queryBuilder.length ? '?' + queryBuilder.join('&') : ''
  return await this.Request({
    url: '/admin/timelogs/reports' + queryBuilder,
    method: 'get'
  })
}

Services.prototype.UploadFilesBeta = async function (file) {
  this.UseV2()
  this.SetApiToken()
  this.SetCustomHeader('Content-Type', 'multipart/form-data')
  const formData = new FormData()
  formData.append('file', file, '_' + encodeURI(file.name.toLowerCase()))
  return await this.Request({
    url: '/admin/beta/file',
    method: 'post',
    data: formData
  })
}

Services.prototype.GetOneNotePagesBeta = async function ({
  page,
  prevPage,
  nextPage
}) {
  this.UseV2()
  this.SetApiToken()
  const queryBuilder = []
  if (page === 'prev') {
    queryBuilder.push(`page=${encodeURIComponent(prevPage)}`)
  }
  if (page === 'next') {
    queryBuilder.push(`page=${encodeURIComponent(nextPage)}`)
  }
  return await this.Request({
    url: `/admin/beta/one-note-pages${
      queryBuilder.length ? '?' + queryBuilder.join('&') : ''
    }`,
    method: 'get'
  })
}

Services.prototype.GetOneNotePageDataBeta = async function (contentLink) {
  this.UseV2()
  this.SetApiToken()
  return await this.Request({
    url: `/admin/beta/one-note-pages/fetch-page-data?link=${encodeURIComponent(
      contentLink
    )}`,
    method: 'get'
  })
}

export default new Services()
</script>
