<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg" v-if="weeks.defaultDates.length && users.length">
        <div class="row calendar-header">
          <div class="col-lg team">Name</div>
          <div
            class="col-lg day-holder"
            :class="{
              today: isToday(date),
              weekend: isWeekend(date) || isHoliday(date)
            }"
            v-for="(date, index) in weeks.defaultDates"
            :key="index"
          >
            {{ formatHeaderDate(date) }}
          </div>
        </div>
        <div
          class="row calendar-body"
          :class="{ bgOdd: index % 2 !== 0 }"
          v-for="(date, index) in weeks.dates"
          :key="date.user.uid + '_' + index"
        >
          <div class="col-lg team" :style="getSelectedStyle(date.user.color)">
            <div class="row">
              <div class="col-sm" style="margin: 10px">
                {{ date.user.name }}
              </div>
            </div>
          </div>
          <div
            class="col-lg block"
            :class="{ weekend: isWeekend(block.date) || isHoliday(block.date) }"
            v-for="(block, index) in date.blocks"
            :key="block.key + '_' + index"
            @click="dropProject(date.user.uid, block.date)"
          >
            <div
              v-for="(project, index) in block.items"
              :key="block.key + '_' + project.aid + '_' + index"
              @click="
                !project.isHoliday
                  ? removeProject(date.user.uid, block.date, project.uid)
                  : () => {}
              "
              :class="{
                project:
                  !project.isHoliday ||
                  !isWeekend(block.date) ||
                  isHoliday(block.date),
                'block-text-shadow':
                  !isWeekend(block.date) &&
                  !isHoliday(block.date) &&
                  !isWFA(block),
                'stripe-bg':
                  !isHoliday(block.date) &&
                  !isWeekend(block.date) &&
                  !isWFA(block) &&
                  project.isHoliday,
                'wfa-stripe-bg':
                  !isHoliday(block.date) &&
                  !isWeekend(block.date) &&
                  isWFA(block) &&
                  project.isHoliday,
                'full-block': block.items.length == 1
              }"
              :style="
                !project.isHoliday ||
                (!isWeekend(block.date) && !isHoliday(block.date))
                  ? setProjectColorAsBg(project)
                  : ''
              "
            >
              <span v-if="!isWeekend(block.date) && !isHoliday(block.date)">
                <h6 v-if="project.isHoliday">
                  <b-badge
                    variant="warning"
                    style="text-transform: uppercase"
                    >{{ project.name }}</b-badge
                  >
                </h6>
                <span v-else>{{ project.name }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'
import {
  startOfWeek,
  addDays,
  format,
  isToday,
  isWeekend,
  differenceInSeconds
} from 'date-fns'

export default {
  name: 'StaffingCalendar',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    },
    showWeekends: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    Store() {
      return this.$store.state.components.staffing
    },
    calendarWeeksStartDate() {
      return this.Store.calendarWeeksStartDate
    },
    projectToDrag() {
      return this.Store.projectToDrag
    }
  },
  data() {
    return {
      date: new Date(),
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      weeks: {
        defaultDates: [],
        dates: [],
        bankHolidays: []
      },
      assignments: []
    }
  },
  watch: {
    users() {
      this.weeks.dates = this.getBlocks()
    },
    calendarWeeksStartDate() {
      this.weeks.dates = this.getBlocks()
    },
    assignments(data) {
      this.weeks.dates = this.getBlocks()
      if (this.weeks.dates.length) {
        this.importAssignmentsToBlocks(data)
      }
    }
  },
  mounted() {
    this.$root.$on('staffing-assignments-updated', this.syncBlocks)
    this.$root.$on('staffing-assignments-fetched', (data) => {
      this.assignments = data
    })
    this.$root.$on('week-changed-on-staffing', async () => {
      this.weeks.defaultDates = await this.setDefaultDates()
    })
  },
  methods: {
    async setDefaultDates() {
      // GET BANK HOLIDAYS
      const { status, data } = await this.getBankHolidays()
      if (status === 200) {
        this.weeks.bankHolidays = data?.content ?? []
      }
      if (this.calendarWeeksStartDate == null) {
        this.$store.commit(
          'setStaffingCalendarWeeksStartDate',
          startOfWeek(new Date(), { weekStartsOn: 1 })
        )
      }
      return new Array(14)
        .fill(0)
        .map((_, i) =>
          format(addDays(this.calendarWeeksStartDate, i), 'yyyy-MM-dd')
        )
        .filter((_, i) => this.showWeekends || i % 7 < 5)
    },
    getBlocks() {
      this.weeks.dates = []
      const start =
        this.calendarWeeksStartDate ||
        startOfWeek(this.date, { weekStartsOn: 1 })
      this.$store.commit('setStaffingCalendarWeeksStartDate', start)
      this.weeks.dates = new Array(14)
        .fill(0)
        .map((_, i) => format(addDays(start, i), 'yyyy-MM-dd'))
        .filter((_, i) => this.showWeekends || i % 7 < 5)

      let Blocks = {}

      this.users.forEach((user) => {
        Blocks[user.uid] = Blocks[user.uid] || {
          user,
          blocks: []
        }
        this.weeks.dates.forEach((date) => {
          const blockIndexToPush = Blocks[user.uid].blocks.findIndex(
            (block) => block.date === date
          )
          if (blockIndexToPush === -1) {
            Blocks[user.uid].blocks.push({
              key: `${user.uid}-${date}`,
              date,
              items: []
            })
          }
        })
      })
      return Object.values(Blocks)
    },
    importAssignmentsToBlocks(data = []) {
      // Group data by users
      data = data
        .filter((itm) => {
          if (!itm.owner) {
            return false
          }
          return (
            this.weeks.dates.findIndex(
              (date) => date.user.uid == itm.owner.uid
            ) != -1
          )
        })
        .reduce((acc, item) => {
          acc[item.owner.uid] = acc[item.owner.uid] || []
          const itemIdx = acc[item.owner.uid].findIndex(
            (itm) => itm.uid == item.uid
          )
          if (itemIdx === -1) {
            // console.log('PUSH-1', item, acc[item.owner.uid])
            acc[item.owner.uid].push(item)
          }
          return acc
        }, {})

      if (!Object.keys(data).length) {
        return
      }
      // Push assignments to blocks
      for (const userUid in data) {
        const blockIndex = this.weeks.dates.findIndex(
          (date) => date.user.uid == userUid
        )
        const groupedByDates = data[userUid].reduce((acc, item) => {
          acc[item.assignmentDate] = acc[item.assignmentDate] || []
          // console.log('PUSH-2', item, acc[item.assignmentDate])
          acc[item.assignmentDate].push(item)
          return acc
        }, {})
        const dataBlocks = this.weeks.dates[blockIndex].blocks
        for (const date in groupedByDates) {
          const dateIndex = dataBlocks.findIndex((block) => block.date == date)
          const dateBlock = this.weeks.dates[blockIndex].blocks[dateIndex]
          if (typeof dateBlock === 'undefined') {
            continue
          }
          dateBlock.items = []
          groupedByDates[date].forEach((item) => {
            if (dateBlock.items.length >= 2) {
              return
            }
            if (item.type === 'project' && item.assignment) {
              // check if already exists
              const exists = dateBlock.items.findIndex(
                (itm) => itm.aid == item.assignment.uid
              )
              if (exists === -1) {
                const ItemToStore = {
                  uid: item?.uid ?? null,
                  aid: item?.assignment?.uid ?? null,
                  name: item?.assignment?.title ?? null,
                  color: item?.assignment?.meta?.color ?? '#000',
                  created: item?.created ?? null,
                  isHoliday: false
                }
                const existedHolidayInBlock = dateBlock.items.find(
                  (itm) => itm.isHoliday
                )
                dateBlock.items.push(ItemToStore)
              }
            }
            if (item.type === 'holiday') {
              const name =
                item.meta.holidayType.charAt(0).toUpperCase() +
                item.meta.holidayType.slice(1).toLowerCase()
              let HolidayName =
                /^HALF.+|^WFH.+|^ANNUAL.+|^DAY\-IN\-LIEU$|^MANDATORY$|^BIRTHDAY$/g.test(
                  item.meta.holidayType
                )
                  ? name
                  : 'Personal/\nSick'
              if (name.toLowerCase() == 'half-day' && item.meta.meridiem) {
                HolidayName += ' (' + item.meta.meridiem + ')'
              }
              if (/^Wfh.+/i.test(HolidayName)) {
                HolidayName = 'WFA'
              }
              if (/^annual.+/i.test(HolidayName)) {
                HolidayName = 'Holiday'
              }
              if (/^mandatory$/i.test(HolidayName)) {
                HolidayName = 'Holiday'
              }
              if (/^birthday$/i.test(HolidayName)) {
                HolidayName = 'Birthday'
              }
              if (/^DAY\-IN\-LIEU$/i.test(HolidayName)) {
                HolidayName = 'DAY-IN-LIEU'
              }
              const ItemToStore = {
                uid: item.uid,
                aid: item.meta.holidayType.toUpperCase(),
                name: HolidayName,
                color:
                  item.meta.holidayType.toUpperCase() == 'ANNUAL'
                    ? '#C39'
                    : '#93C',
                created: item?.created ?? null,
                isHoliday: true,
                meridiem: item?.meta?.meridiem ?? null
              }
              const HolidaysOnLessPriority = [
                'WFA',
                'Half-day (AM)',
                'Half-day (PM)',
                'Half personal'
              ]
              if (HolidaysOnLessPriority.includes(ItemToStore.name)) {
                if (ItemToStore.name === HolidaysOnLessPriority[1]) {
                  dateBlock.items.unshift(ItemToStore)
                } else {
                  dateBlock.items.push(ItemToStore)
                }
              } else {
                dateBlock.items = [ItemToStore]
              }
            }
          })
          // filter duplicates
          dateBlock.items = dateBlock.items.reduce((acc, item) => {
            const isExisted = acc.findIndex((itm) => itm.aid === item.aid)
            if (isExisted === -1) {
              acc.push(item)
            }
            return acc
          }, [])
        }
      }
    },
    syncBlocks({ action = 'add', data }) {
      if (action === 'add') {
        this.assignments.push(data)
      }
      this.$root.$emit('week-changed-on-staffing')
    },
    dropProject(userUid, date) {
      if (!this.isValidProjectToDrop(date)) {
        return
      }
      const blockIndex = this.getBlockIndexs(userUid, date)

      let isValidToAssignProject = true

      isValidToAssignProject =
        isValidToAssignProject &&
        this.weeks.dates[blockIndex.date].blocks[blockIndex.block].items
          .length != 2

      const uuidRegEx = new RegExp(
        '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
        'ig'
      )
      const holidayBlock = this.weeks.dates[blockIndex.date].blocks[
        blockIndex.block
      ].items.find((itm) => {
        return !uuidRegEx.test(itm.aid.toUpperCase())
      })
      if (holidayBlock) {
        isValidToAssignProject =
          isValidToAssignProject && /^HALF.+$|^WFH.+/g.test(holidayBlock.aid)
      } else {
        isValidToAssignProject =
          isValidToAssignProject &&
          !this.weeks.dates[blockIndex.date].blocks[blockIndex.block].items
            .length
      }

      const isDuplicated =
        this.weeks.dates[blockIndex.date].blocks[
          blockIndex.block
        ].items.findIndex((itm) => itm.aid == this.projectToDrag.uid) != -1

      if (isValidToAssignProject && !isDuplicated) {
        this.weeks.dates[blockIndex.date].blocks[blockIndex.block].items.push(
          this.projectToDrag
        )
        this.$root.$emit('new-staffing-assignment', {
          userUid,
          date,
          projectUid: this.projectToDrag.uid
        })
      }
    },
    removeProject(userUid, date, assignmentUid) {
      if (this.projectToDrag) {
        return
      }
      const assignmentIndex = this.assignments.findIndex(
        (itm) => itm.uid == assignmentUid
      )
      this.assignments.splice(assignmentIndex, 1)
      this.$root.$emit('remove-staffing-assignment', {
        userUid,
        date,
        assignmentUid
      })
    },
    isValidProjectToDrop(date) {
      let isValid = true
      isValid = isValid && !this.isWeekend(date)
      isValid = isValid && !this.isHoliday(date)
      isValid = isValid && this.projectToDrag
      return isValid
    },
    getBlockIndexs(userUid, date) {
      const indexs = {
        date: -1,
        block: -1
      }
      indexs.date = this.weeks.dates.findIndex(
        (dateBlock) => dateBlock.user.uid === userUid
      )
      indexs.block =
        this.weeks.dates[indexs.date]?.blocks.findIndex(
          (block) => block.date === date
        ) ?? -1
      return indexs
    },
    sortBlockItems(a, b) {
      console.log({ a, b })
      if (a.isHoliday && !b.isHoliday) {
        if (a.meridiem == 'AM') return -1
        if (a.meridiem == 'PM') return 1
      } else {
        if (a.created > b.created) return 1
        if (a.created < b.created) return -1
      }
      return 0
    },
    isToday(date) {
      return isToday(new Date(date))
    },
    isWeekend(date) {
      return isWeekend(new Date(date))
    },
    isHoliday(date) {
      return this.weeks.bankHolidays.findIndex((itm) => itm.date == date) != -1
    },
    isWFA(block) {
      return block.items.findIndex((itm) => itm.name == 'WFA') != -1
    },
    formatHeaderDate(date) {
      return format(new Date(date), 'EEE do')
    },
    getSelectedStyle(color) {
      return {
        'background-color': color,
        color: '#fff'
      }
    },
    setProjectColorAsBg(project) {
      if (!project || !project.aid) {
        return {
          background: 'rgba(255, 255, 255, 0.5) !important',
          height: '48% !important'
        }
      }
      const uuidRegEx = new RegExp(
        '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
        'ig'
      )
      const isHolidayBlock = !uuidRegEx.test(
        project?.aid?.toUpperCase() ?? null
      )
      const isFullBlock =
        isHolidayBlock && /^HALF\-DAY$|^WFH.+/g.test(project.aid)
      return {
        'background-color': project.color,
        height: (!isFullBlock ? '98%' : '48%') + ' !important'
      }
    },
    async getBankHolidays() {
      return await ServicesOld.GetBankHolidays()
    }
  }
}
</script>

<style scoped>
.calendar-header {
  background-color: rgb(0, 123, 255);
  padding: 0 !important;
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
  height: 40px;
  line-height: 40px;
  max-width: 100% !important;
}
.calendar-header .team {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
  text-align: center;
  width: 100px;
  max-width: calc(100% / 15) !important;
}
.calendar-header .loading {
  background-color: rgb(177, 50, 50) !important;
  color: #fff;
}
.calendar-header .day-holder {
  text-align: center;
  padding: 0 !important;
  max-width: calc(100% / 15) !important;
}
.calendar-header .day-holder.today {
  background-color: rgb(0, 51, 102) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend.today {
  color: rgb(0, 123, 255) !important;
}
.calendar-body {
  background-color: #eee;
  min-height: 60px;
  max-height: 60px;
  padding: 0 !important;
  max-width: 100% !important;
}
.calendar-body.bgOdd {
  background-color: #f5f5f5 !important;
  padding: 0 !important;
}
.calendar-body .team {
  color: #fff;
  background-color: #333;
  font-size: 10pt;
  font-weight: bold;
  padding: 0 !important;
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: center;
  height: 60px !important;
  max-height: 60px !important;
  max-width: calc(100% / 15) !important;
  border-top: 1px solid #eee !important;
  text-shadow: #666 0 0 3px;
}
.calendar-body .block {
  display: block;
  text-align: center;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  padding: 0 !important;
  height: 60px !important;
  max-height: 60px !important;
  max-width: calc(100% / 15) !important;
}
.calendar-body .block.weekend {
  background-color: rgb(177, 177, 177) !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.calendar-body .block:hover {
  background-color: #ccc;
  cursor: default;
  padding: 0 !important;
}
.calendar-body .block .project {
  border-radius: 0;
  margin: 0.5% auto;
  padding: 1%;
  font-size: 10pt;
  color: #fff;
  font-weight: bold;
  overflow-wrap: break-word;
  overflow-y: auto;
  max-width: 100% !important;
  min-height: 50% !important;
  max-height: 50% !important;
  height: 50% !important;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  /* text-shadow: #666 0 0 3px; */
}
.calendar-body .block .project.full-block {
  min-height: 99% !important;
  max-height: 99% !important;
  height: 99% !important;
}
.block-text-shadow {
  text-shadow: #666 0 1px 2px !important;
}
.stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #93c 10px,
      #93c 20px
    ),
    linear-gradient(to bottom, #c39, #93c);
}
.wfa-stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #400d01 10px,
      #400d01 20px
    ),
    linear-gradient(to bottom, #592202, #400d01);
}
</style>
