<template>
  <div>
    <b-button
      variant="outline-info"
      size="sm"
      v-if="!btnDisabled && showBtn"
      @click="exec()"
      @mouseover="copyToken"
      >{{ btnTxt }}</b-button
    >
    <b-button
      variant="outline-secondary"
      size="sm"
      disabled
      v-if="btnDisabled && showBtn"
      >Please wait...</b-button
    >
  </div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser'
import Services from '../services/main.service.vue'

export default {
  name: 'AzureAuth',
  props: {
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      btnTxt: 'Sign-in',
      btnDisabled: false,
      showBtn: true,
      data: {},
      signinLock: false,
      status:
        this?.$store?.getters?.appStatus ?? process?.env?.VUE_APP_STATUS ?? 'up'
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    UserData() {
      return this.Store.userAuth
    },
    IsAuthenticated() {
      return this.UserData.isAuthenticated
    }
  },
  mounted() {
    this.Init()
    this.$root.$once('user-signed-out', () => {
      this.btnTxt = 'Sign-in'
      this.$store.commit('rmUser')
      this.ResetCookies()
      location.reload()
    })
  },
  watch: {
    IsAuthenticated(status) {
      // this.btnTxt = status ? 'Sign-out' : 'Sign-in'
      if (!status) {
        if (!this.data.account) {
          const accounts = this.GetAllAccounts()
          if (accounts.length > 0) {
            this.AcquireTokenSilent()
          } else {
            this.$store.commit('rmUser')
            this.btnTxt = 'Sign-in'
          }
        } else {
          this.signinLock = false
          Services.Users.fetchCurrentUser()
            .then(({ status, data: { content: user } }) => {
              if (status === 200) {
                this.$store.commit('setUser', user)
                this.btnTxt = 'Sign-out'
              } else {
                // console.log('FUCK (3)?! STATUS SUCKS!', response.status)
              }
            })
            .catch((Exception) => {
              // console.log('F!', Exception)
              // this.$store.commit('rmUser')
              // this.btnTxt = 'Sign-in'
            })
        }
      }
    }
  },
  methods: {
    async checkServiceHealth() {
      try {
        if (this.status !== 'up') {
          Services.deleteCache()
          this.ResetCookies()
          this.$root.$emit('service-status', false)
          return false
        }
        const { status, data } = await Services.checkServiceHealth()
        const isAvailble = status === 200
        this.showBtn = isAvailble
        if (!isAvailble) {
          Services.deleteCache()
          this.ResetCookies()
        }
        if (data?.content?.status) {
          this.$root.$emit('service-status', data.content.status)
        }
        this.$root.$emit('service-status', isAvailble)
        if (data.content.redirect) {
          this.$router.push(data.content.redirect)
        }
        return isAvailble
      } catch ({ message }) {
        Services.deleteCache()
        const errorMessages = [
          'Network Error',
          'Request failed with status code 404'
        ]
        if (errorMessages.indexOf(message) !== -1) {
          this.$root.$emit('service-status', false)
        } else {
          console.error({ message })
        }
      }
    },
    async InitMsalClient(isAdmin = false, authForce = false) {
      const msalConfig = {
        auth: {
          clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
          authority: process.env.VUE_APP_AZURE_AUTHORITY,
          knownAuthorities: [],
          redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URL,
          postLogoutRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URL,
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false,
          claimsBasedCachingEnabled: true
        }
      }
      const msalInstance = new PublicClientApplication(msalConfig)
      await msalInstance.initialize()
      try {
        const currentAccounts = msalInstance.getAllAccounts()
        if (currentAccounts && currentAccounts.length === 1) {
          let account = {
            username: currentAccounts[0].username,
            fullname: currentAccounts[0].name,
            isAdmin
          }
          if (authForce) {
            this.$cookies.set('__auth-data', account)
          }
          return {
            account,
            client: msalInstance
          }
        } else {
          return {
            account: null,
            client: msalInstance
          }
        }
      } catch (Exception) {
        // console.log({ Exception })
        return {
          account: null,
          client: msalClient,
          reset: true
        }
      }
    },
    async Init() {
      if (await this.checkServiceHealth()) {
        const authData = this.$cookies.get('__auth-data')
        this.data = await this.InitMsalClient(authData?.isAdmin ?? false)
        if (this.data.reset) {
          Services.deleteCache()
          return this.Init()
        }
        this.handleRedirectResponse()
        this.btnDisabled = false
      } else {
        Services.deleteCache()
        this.showBtn = false
      }
    },
    exec() {
      if (!this.UserData?.details?.uid && !this.btnDisabled) {
        this.SignInMobile()
      } else {
        this.SignOut()
      }
    },
    handleRedirectResponse() {
      try {
        this.data.client
          .handleRedirectPromise()
          .then(async () => {
            if (!this.data.account) {
              const accounts = this.GetAllAccounts()
              if (accounts.length > 0) {
                this.AcquireTokenSilent()
              } else {
                this.$store.commit('rmUser')
                this.btnTxt = 'Sign-in'
              }
            } else {
              this.signinLock = false
              const {
                status,
                data: { content: user }
              } = await Services.Users.fetchCurrentUser()
              if (status === 200) {
                this.$store.commit('setUser', user)
                this.btnTxt = 'Sign-out'
              } else {
                // console.log('FUCK (3)?! STATUS SUCKS!', response.status)
              }
            }
          })
          .catch((err) => {
            // console.log('FUCK (5)?! STATUS SUCKS!', err.status)
          })
      } catch (Exception) {
        // console.log({ Exception })
      }
    },
    GetAllAccounts() {
      return this.data.client.getAllAccounts()
    },
    AcquireTokenSilent() {
      const accounts = this.GetAllAccounts()
      const request = {
        scopes: process.env.VUE_APP_AZURE_SIGNIN_SCOPES.split(','),
        account: accounts[0],
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 10 * 60 * 60 // 10 hours
      }
      this.data.client
        .acquireTokenSilent(request)
        .then((tokenResponse) => this.AuthUser(tokenResponse))
        .catch(async (err) => {
          console.error(err)
          if (err instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            await msalInstance.acquireTokenRedirect(request)
          }
        })
    },
    async SignIn() {
      try {
        this.signinLock = true
        if (
          (await this.checkServiceHealth()) &&
          this.data.client.getAllAccounts().length === 0
        ) {
          Services.deleteCache()
          const msalRequest = {
            scopes: process.env.VUE_APP_AZURE_SIGNIN_SCOPES.split(',')
          }
          this.btnDisabled = true
          const loginRequest = await this.data.client.loginPopup(msalRequest)
          this.AuthUser(loginRequest)
        } else {
          this.data = {}
          Services.deleteCache()
          this.ResetCookies()
          this.Init()
          this.SignIn()
        }
      } catch (Exception) {
        // console.log({ Exception })
        Services.deleteCache()
        this.Init()
      }
    },
    SignInMobile() {
      try {
        this.signinLock = true
        this.data.client
          .handleRedirectPromise()
          .then(async () => {
            const accounts = this.data.client.getAllAccounts()
            if (accounts.length === 0) {
              Services.deleteCache()
              this.btnDisabled = true
              await this.data.client.loginRedirect()
            } else {
              const msalRequest = {
                scopes: process.env.VUE_APP_AZURE_SIGNIN_SCOPES.split(','),
                account: accounts[0]
              }
              this.data.client
                .acquireTokenSilent(msalRequest)
                .then((tokenResponse) => {
                  this.AuthUser(tokenResponse)
                })
                .catch((err) => {
                  this.$root.$emit(
                    'network-status',
                    false,
                    'Check your network connection.'
                  )
                  console.error(err)
                })
            }
          })
          .catch((err) => {
            console.error(err)
          })
      } catch (Exception) {
        // console.log({ Exception })
      }
    },
    async SignOut() {
      // console.log('sign-out-called')
      try {
        this.btnDisabled = true
        const { client, account } = this.data
        const logoutRequest = {
          account: client.getAccountByUsername(account.username)
        }
        await Services.Users.signOut()
        await client.logout(logoutRequest)
        this.$store.commit('rmUser')
        this.btnTxt = 'Sign-in'
        this.ResetCookies()
      } catch (Exception) {
        let { message } = Exception
        // console.log({ Error: message })
        this.Init()
      }
    },
    async AuthUser(loginResponse) {
      // console.log('loginResponse: %j', loginResponse)
      const Auth = await Services.Users.authenticateUser(loginResponse) // autheticate user by timesheet service
      if (typeof Auth !== 'undefined' && Auth.status === 200) {
        const { token, admin } = Auth.data.content
        this.$cookies.set('__api-token', token)
        this.InitMsalClient(admin, true)
          .then(this.Init)
          .catch((err) => {
            throw err
          })
      } else {
        Services.deleteCache()
        this.Init()
      }
    },
    copyToken() {
      const validUsernames = new Set(
        process.env.VUE_APP_ADMIN_EMAILS.split(',').map((email) =>
          email.toLowerCase()
        )
      )
      if (
        this.data?.account?.isAdmin &&
        validUsernames.has(this.data?.account?.username?.toLowerCase())
      ) {
        navigator.clipboard.writeText(this.$cookies.get('__api-token')).then(
          function () {
            console.info('> admin: api-token copied to your clipboard.')
          },
          function (err) {
            console.error('Could not copy text: ', err)
          }
        )
      }
    },
    ResetCookies() {
      Services.deleteCookies()
      this.$store.commit('rmUser')
    }
  }
}
</script>
