<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-12 text-left header-msg">
        <h6>
          <span v-if="!inprocess">Queued List</span>
          <span v-if="inprocess">Saving...</span>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!holidayItems.length && !expenseItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary"
            >Please use entry form to add new items here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="!holidayItems.length && !expenseItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="expenseItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary">Expenses</b-badge>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="expenseItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="info"
          :items="expenseItems"
          :fields="expenseFields"
        >
          <template #table-caption
            >Please remember you have to submit these records!</template
          >
          <template #cell(project)="data">
            {{
              data.item.expenseProjType === 'bd'
                ? data.item.project.split('_')[0]
                : data.item.project.split(':')[0]
            }}
          </template>
          <template #cell(date)="data">
            {{ formatDate(data.item.date) }}
          </template>
          <template #cell(amount)="data">
            {{ currencySymbol(data.item.currency)
            }}{{ parseFloat(data.item.amount).toFixed(2) }}
          </template>
          <template #cell(vat)="data"
            >{{ currencySymbol(data.item.currency)
            }}{{ parseFloat(data.item.vat).toFixed(2) }}</template
          >
          <template #cell(action)="data">
            <b-link
              v-if="
                data.value.status === 'queued' && showActions.expense.remove
              "
              @click="remove(data.value.id, 'expense')"
            >
              <b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon></b-link
            ><b-icon
              v-if="data.value.status === 'pending'"
              icon="hourglass-split"
              variant="info"
              title="Pending"
            ></b-icon
            ><b-icon
              v-if="data.value.status === 'done'"
              icon="check-circle-fill"
              variant="success"
              title="Saved"
            ></b-icon></template
        ></b-table>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="expenseItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="holidayItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary">Holidays</b-badge>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="holidayItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          responsive
          striped
          bordered
          outlined
          fixed
          small
          hover
          class="lizard-activity-list"
          variant="secondary"
          :items="holidayItems"
          :fields="holidayFields"
        >
          <template #table-caption
            >Please remember you have to submit these records!</template
          >
          <template #cell(fromDate)="data">
            {{ formatDate(data.item.fromDate) }}
          </template>
          <template #cell(toDate)="data">
            {{ formatDate(data.item.toDate) }}
          </template>
          <template #cell(action)="data">
            <b-link
              v-if="
                data.value.status === 'queued' && showActions.holiday.remove
              "
              @click="remove(data.value.id, 'holiday')"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon
            ></b-link>
            <b-icon
              v-if="data.value.status === 'pending'"
              icon="hourglass-split"
              variant="info"
              title="Pending"
            ></b-icon>
            <b-icon
              v-if="data.value.status === 'done'"
              icon="check-circle-fill"
              variant="success"
              title="Saved"
            ></b-icon>
          </template>
        </b-table>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="holidayItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="holidayItems.length || expenseItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-right">
        <b-button
          size="sm"
          v-if="!inprocess"
          variant="info"
          @click="submitEntries"
          >Submit</b-button
        >
        <b-button
          size="sm"
          disabled
          v-if="inprocess"
          variant="outline-secondary"
          >Please wait...</b-button
        >
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" v-if="holidayItems.length || expenseItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<style scoped>
.lizard-activity-list-action a {
  margin: 0 3px;
}
</style>

<style>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  /* font-weight: bold !important; */
}
.lizard-activity-list-index {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 25% !important;
}
.lizard-activity-list-date {
  width: 20% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 10% !important;
  text-align: center !important;
}
.lizard-activity-list-amount {
  width: 15% !important;
  text-align: center !important;
}
.lizard-activity-list-action {
  max-width: 15% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
</style>

<script>
import ServicesOld from '../services/index.vue'
import Services from '../services/main.service.vue'
import { format as dateFormat } from 'date-fns'

export default {
  name: 'ActivityList',
  data() {
    return {
      inprocess: false,
      showList: false,
      showActions: {
        holiday: { remove: true },
        expense: { remove: true }
      },
      expenseItems: [],
      holidayItems: [],
      holidayFields: [
        {
          key: 'holidayLabel',
          label: 'Type'
        },
        {
          key: 'fromDate',
          label: 'From',
          sortable: true
        },
        {
          key: 'toDate',
          label: 'To'
        },
        {
          key: 'duration',
          label: 'Days'
        },
        {
          key: 'action',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center'
        }
      ],
      expenseFields: [
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'project',
          label: 'Project',
          sortable: true
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'vat',
          label: '+VAT'
        },
        {
          key: 'action',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center'
        }
      ]
    }
  },
  computed: {
    items() {
      return this.$store.getters.activityItems
    }
  },
  mounted() {
    this.listEntries()
  },
  methods: {
    listEntries() {
      if (this.inprocess) {
        return
      }
      let { expenseItems, holidayItems } = this.items
      this.expenseItems = expenseItems.map((itm, idx) => {
        itm.date = itm.dates[0]
        itm.action = {
          status: 'queued',
          id: idx
        }
        return itm
      })
      this.holidayItems = holidayItems.map((itm, idx) => {
        itm.fromDate = itm.dates[0]
        itm.toDate = itm.dates[1] || itm.dates[0]
        itm.haldDayMeridiem = itm.holidayHalfDayMeridiem
        itm.action = {
          status: 'queued',
          id: idx
        }
        return itm
      })
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    clearEntries() {
      this.$store.commit('clearActivityEnteries')
      this.holidayItems = []
      this.expenseItems = []
    },
    remove(id = 0, list = 'list') {
      if (!this.showActions[list].remove) {
        alert('Under Development!')
        return
      }
      list += 'Items'
      if (this[list].length === 1) {
        this[list] = []
      } else {
        this[list].splice(id, 1)
      }
      this.$store.commit('modifyActivityEnteries', {
        list: list,
        value: this[list]
      })
    },
    submitEntries() {
      try {
        const totalEntries = this.holidayItems.length + this.expenseItems.length
        this.inprocess = totalEntries.length
        if (!totalEntries) {
          this.inprocess = false
          return
        }
        let queue = new Array()
        queue = queue.concat(this.holidayItems)
        queue = queue.concat(this.expenseItems)
        queue = queue.filter((itm) => {
          return itm.action.status === 'queued'
        })
        queue.map((itm) => {
          itm.action.status = 'pending'
          return itm
        })
        setTimeout(() => {
          // console.log('saving-queue:', queue)
          this.sendEntry(queue)
        }, 500)
      } catch (err) {
        this.inprocess = false
        console.log(err)
      }
    },
    async sendEntry(queue, idx = 0) {
      let localVar = queue[idx]
      if (typeof localVar === 'undefined') {
        idx = idx < queue.length - 1 ? idx + 1 : 0
        this.inprocess = false
        return
      }
      try {
        let saved = null
        if (queue[idx].type === 'holiday') {
          saved = await ServicesOld.AddHoliday(queue[idx])
        } else if (queue[idx].type === 'expense') {
          saved = await ServicesOld.AddExpense(queue[idx])
        }
        localVar.action.status = 'queued'
        if (saved.status === 200) {
          localVar.action.status = 'done'
        }
        if (idx < queue.length - 1) {
          idx++
          return this.sendEntry(queue, idx)
        } else {
          this.inprocess = false
          this.resetList()
        }
      } catch (err) {
        const error =
          err?.response?.data?.error?.details?.validationErrors ?? []
        console.error(error)
        const defaultMsg =
          'Please review your entries and attempt the submission again.'
        this.$bvToast.toast(error.length ? error[0].message : defaultMsg, {
          title: String(
            'Error in saving ' + localVar?.type ?? 'Holiday'
          ).trim(),
          variant: 'danger',
          solid: true
        })
        if (idx < queue.length - 1) {
          idx++
          return this.sendEntry(queue, idx)
        } else {
          this.inprocess = false
        }
      }
    },
    resetList() {
      // this.$root.$emit('saved')
      this.expenseItems = []
      this.holidayItems = []
      this.clearEntries()
      this.$root.$emit('saved')
    },
    formatDate(date) {
      return dateFormat(new Date(date), 'dd/MM/yyyy')
    }
  },
  watch: {
    'items.expenseItems': {
      handler: 'listEntries',
      immediate: true
    },
    'items.holidayItems': {
      handler: 'listEntries',
      immediate: true
    }
  }
}
</script>
