var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bg-white",attrs:{"fluid":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-left txt"},[_vm._v(" reports matching selected filters (you can filter the status by clicking on the box labels) ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg",staticStyle:{"margin":"0 15px"}},[_c('div',{staticClass:"row"},_vm._l((_vm.expenseStatusOpts),function(statusItem){return _c('div',{key:statusItem.value,staticClass:"col-lg-3 report-box",class:_vm.GetReportBoxClass(statusItem.value),attrs:{"id":'report-box-' + statusItem.value}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 text-left"},[_c('h5',[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:({ variant: 'light' }),expression:"{ variant: 'light' }",modifiers:{"hover":true,"bottom":true}}],staticStyle:{"cursor":"pointer"},attrs:{"variant":_vm.GetExpenseStatusVariant(statusItem.value),"title":'Filter ' + statusItem.text.toLowerCase() + ' requests'},on:{"click":function($event){return _vm.FilterByStatus(statusItem.value)}}},[_vm._v(_vm._s(statusItem.value))])],1)]),_c('div',{staticClass:"col-lg-8 text-right"},[(_vm.reportsLabelStatus[statusItem.value])?_c('ul',_vm._l((_vm.reportStats),function(stat,index){return _c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:({ variant: 'light' }),expression:"{ variant: 'light' }",modifiers:{"hover":true,"bottom":true}}],key:index,staticClass:"report-stat-btn",class:{
                    selected: _vm.IsReportStatSelected(statusItem.value, index)
                  },style:({
                    display: _vm.showStatField(stat, statusItem.value)
                      ? 'inline-block'
                      : 'none'
                  }),attrs:{"title":stat.alt},on:{"click":function($event){return _vm.SelectReportStat(statusItem.value, index)}}},[_c('b-icon',{attrs:{"icon":stat.icon,"font-scale":"1.2"}})],1)}),0):_vm._e()])]),(_vm.reportsLabelStatus[statusItem.value])?_c('div',{staticClass:"row"},[(
                _vm.GetSelectedReportStatValueType(statusItem.value) === 'html'
              )?_c('div',{staticClass:"col-lg-12 text-left",domProps:{"innerHTML":_vm._s(_vm.GetSelectedReportStatValue(statusItem.value))}}):_vm._e(),(
                _vm.GetSelectedReportStatValueType(statusItem.value) === 'table'
              )?_c('div',{staticClass:"col-lg-12 text-left report-box-value"},[(_vm.GetSelectedReportStatValue(statusItem.value).items)?_c('b-table',{attrs:{"items":_vm.GetSelectedReportStatValue(statusItem.value).items,"fields":_vm.GetSelectedReportStatValue(statusItem.value).fields,"variant":_vm.GetExpenseStatusVariant(statusItem.value),"caption-top":"","responsive":"","fixed":"","small":"","borderless":"","striped":"","table-class":"lizard-activity-list lizard-expenses-box lizard-report-box","thead-class":"label","tbody-class":"value"},scopedSlots:_vm._u([{key:"table-caption",fn:function(){return [_vm._v(" "+_vm._s(_vm.selectedStats[statusItem.value] || '-')+" ")]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:({ variant: 'info' }),expression:"{ variant: 'info' }",modifiers:{"hover":true,"bottom":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":"Click to include in filters"},on:{"click":function($event){return _vm.userChanged(data.item)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"cell(title)",fn:function(data){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:({ variant: 'info' }),expression:"{ variant: 'info' }",modifiers:{"hover":true,"bottom":true}}],staticClass:"selected-project",staticStyle:{"margin-bottom":"5px"},style:('background-color: ' + data.item.color + ' !important'),attrs:{"title":"Click to include in filters"},on:{"click":function($event){return _vm.projectChanged(data.item)}}},[_vm._v(" "+_vm._s(data.item.title)+" ")])]}},{key:"cell(label)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.label)+" ")]}}],null,true)}):_vm._e()],1):_vm._e()]):_vm._e()])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }