<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-12 text-left txt">
        reports matching selected filters (you can filter the status by clicking
        on the box labels)
      </div>
    </div>
    <div class="row">
      <div class="col-lg" style="margin: 0 15px">
        <div class="row">
          <div
            :id="'report-box-' + statusItem.value"
            class="col-lg-3 report-box"
            :class="GetReportBoxClass(statusItem.value)"
            v-for="statusItem in expenseStatusOpts"
            :key="statusItem.value"
          >
            <div class="row">
              <div class="col-lg-4 text-left">
                <h5>
                  <b-badge
                    style="cursor: pointer"
                    @click="FilterByStatus(statusItem.value)"
                    :variant="GetExpenseStatusVariant(statusItem.value)"
                    v-b-tooltip.hover.bottom="{ variant: 'light' }"
                    :title="
                      'Filter ' + statusItem.text.toLowerCase() + ' requests'
                    "
                    >{{ statusItem.value }}</b-badge
                  >
                </h5>
              </div>
              <div class="col-lg-8 text-right">
                <ul v-if="reportsLabelStatus[statusItem.value]">
                  <li
                    v-for="(stat, index) in reportStats"
                    :key="index"
                    class="report-stat-btn"
                    :class="{
                      selected: IsReportStatSelected(statusItem.value, index)
                    }"
                    :style="{
                      display: showStatField(stat, statusItem.value)
                        ? 'inline-block'
                        : 'none'
                    }"
                    v-b-tooltip.hover.bottom="{ variant: 'light' }"
                    :title="stat.alt"
                    @click="SelectReportStat(statusItem.value, index)"
                  >
                    <b-icon :icon="stat.icon" font-scale="1.2"></b-icon>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row" v-if="reportsLabelStatus[statusItem.value]">
              <div
                class="col-lg-12 text-left"
                v-if="
                  GetSelectedReportStatValueType(statusItem.value) === 'html'
                "
                v-html="GetSelectedReportStatValue(statusItem.value)"
              ></div>
              <div
                class="col-lg-12 text-left report-box-value"
                v-if="
                  GetSelectedReportStatValueType(statusItem.value) === 'table'
                "
              >
                <b-table
                  v-if="GetSelectedReportStatValue(statusItem.value).items"
                  :items="GetSelectedReportStatValue(statusItem.value).items"
                  :fields="GetSelectedReportStatValue(statusItem.value).fields"
                  :variant="GetExpenseStatusVariant(statusItem.value)"
                  caption-top
                  responsive
                  fixed
                  small
                  borderless
                  striped
                  table-class="lizard-activity-list lizard-expenses-box lizard-report-box"
                  thead-class="label"
                  tbody-class="value"
                >
                  <template #table-caption class="expensereport-caption">
                    {{ selectedStats[statusItem.value] || '-' }}
                  </template>
                  <template #cell(name)="data">
                    <div
                      style="cursor: pointer"
                      @click="userChanged(data.item)"
                      v-b-tooltip.hover.bottom="{ variant: 'info' }"
                      title="Click to include in filters"
                    >
                      {{ data.item.name }}
                    </div>
                  </template>
                  <template #cell(title)="data">
                    <div
                      v-b-tooltip.hover.bottom="{ variant: 'info' }"
                      title="Click to include in filters"
                      @click="projectChanged(data.item)"
                      class="selected-project"
                      style="margin-bottom: 5px"
                      :style="
                        'background-color: ' + data.item.color + ' !important'
                      "
                    >
                      {{ data.item.title }}
                    </div>
                  </template>
                  <template #cell(label)="data">
                    <!-- <div
                      v-b-tooltip.hover.bottom="{ variant: 'info' }"
                      title="Click to include in filters"
                      @click="UpdateSearchQuery(data.item.label)"
                      style="cursor: pointer"
                    >
                      {{ data.item.label }}
                    </div> -->
                    {{ data.item.label }}
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import { format as dateFormat, addMonths, subDays } from 'date-fns'

export default {
  name: 'ReportsBox',
  props: {
    expenseStatusOpts: {
      type: Array,
      required: true
    },
    remoteFilters: {
      type: Object,
      default: {}
    },
    rangeEnums: {
      type: Object,
      required: true
    },
    userChanged: {
      type: Function,
      default: () => {}
    },
    projectChanged: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      MonthsList: [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
      ],
      RangeFilter: {
        month: null,
        year: null
      },
      CurrencySymbol: {
        GBP: '£',
        USD: '$',
        EUR: '€'
      },
      reportsLabelStatus: {
        PENDING: true,
        APPROVED: true,
        REJECTED: true,
        PAID: true
      },
      reportStats: [
        {
          key: 'amount',
          icon: 'cash-stack',
          alt: 'Total Amount',
          selected: ['PENDING', 'APPROVED', 'REJECTED', 'PAID'],
          show: true
        },
        {
          key: 'total',
          icon: 'hash',
          alt: 'Total Requests',
          selected: [],
          show: true
        },
        {
          key: 'users',
          icon: 'people',
          alt: 'Users',
          selected: [],
          show: true
        },
        {
          key: 'projects',
          icon: 'journals',
          alt: 'Projects',
          selected: [],
          show: true
        },
        {
          key: 'categories',
          icon: 'columns',
          alt: 'Categories',
          selected: [],
          show: true
        }
      ],
      reportsData: {},
      selectedStats: {}
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    SelectedExpenseStatus() {
      return this.Store.selectedExpenseStatus
    },
    Filter() {
      if (!this.RangeFilter.year) {
        this.RangeFilter.year = new Date().getFullYear()
      }
      const fdt = new Date()
      fdt.setFullYear(this.RangeFilter.year)
      fdt.setMonth(
        this.MonthsList.indexOf(this.RangeFilter.month.toLowerCase())
      )
      fdt.setDate(1)
      fdt.setHours(0)
      fdt.setMinutes(0)
      fdt.setSeconds(0)
      fdt.setMilliseconds(0)
      return {
        fromDate: dateFormat(fdt, 'yyyy-MM-dd'),
        toDate: dateFormat(subDays(addMonths(fdt, 1), 1), 'yyyy-MM-dd')
      }
    }
  },
  methods: {
    async fetchReports(filters = {}) {
      filters = Object.keys(filters).length
        ? {
            dateLabel: this.rangeEnums.dateLabel.findIndex(
              (label) => label === filters.dateLabel
            ),
            months: filters.months
              .map((month) =>
                this.rangeEnums.months.findIndex((mn) => mn === month)
              )
              .join(','),
            year: filters.year,
            status: filters.status.join(','),
            owner: filters.user,
            projectUid: filters.project,
            category: filters.category
          }
        : {}
      const { status, data } = await ServicesOld.GetExpenseReports(filters)
      if (status === 200) {
        this.reportsData = data.content.status
      }
    },
    changeMonth(dir) {
      const selectedMonthIndex = this.MonthsList.indexOf(
        this.RangeFilter.month.toLowerCase()
      )
      let newMonthIndex = selectedMonthIndex + dir
      newMonthIndex = newMonthIndex < 0 ? 11 : newMonthIndex
      newMonthIndex = newMonthIndex > 11 ? 0 : newMonthIndex
      this.RangeFilter.month = this.MonthsList[newMonthIndex]
      if (newMonthIndex === 11 && dir === -1) {
        this.RangeFilter.year = this.RangeFilter.year - 1
      }
      if (newMonthIndex === 0 && dir === 1) {
        this.RangeFilter.year = this.RangeFilter.year + 1
      }
      this.fetchReports()
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    GetExpenseStatusVariant(status = 'pending') {
      status = status.toLowerCase()
      let variant = 'secondary'
      if (!this.reportsLabelStatus[status.toUpperCase()]) {
        return variant
      }
      switch (status.toLowerCase()) {
        case 'approved':
          variant = 'info'
          break
        case 'rejected':
          variant = 'danger'
          break
        case 'pending':
          variant = 'warning'
          break
        case 'paid':
          variant = 'success'
          break
        case 'failed':
          variant = 'dark'
          break
      }
      return variant
    },
    GetReportBoxClass(boxId) {
      return (
        'report-box-' +
        boxId.toLowerCase() +
        (this.reportsLabelStatus[boxId] ? '' : ' disabled')
      )
    },
    IsReportStatSelected(statKey, statIndex) {
      return this.reportStats[statIndex].selected.includes(statKey)
    },
    SelectReportStat(statKey, statIndex) {
      this.selectedStats[statKey] = this.selectedStats[statKey] || '-'
      this.selectedStats[statKey] = this.reportStats[statIndex].alt
      this.reportStats = this.reportStats.map((itm) => {
        itm.selected = itm.selected.filter((key) => key !== statKey)
        return itm
      })
      this.reportStats[statIndex].selected.push(statKey)
    },
    GetSelectedReportStatValueType(statKey) {
      const statBlock = this.reportStats.find((itm) => {
        return itm.selected.includes(statKey)
      })
      switch (statBlock.key) {
        case 'amount':
        case 'total':
          return 'html'
        case 'users':
        case 'projects':
        case 'categories':
          return 'table'
        default:
          return 'html'
      }
    },
    GetSelectedReportStatValue(statKey) {
      const statData = this.reportsData[statKey] || null
      if (!statData) {
        return '<h4>-</h4>'
      }
      const statBlock = this.reportStats.find((itm) => {
        return itm.selected.includes(statKey)
      })
      let result = ''
      switch (statBlock.key) {
        case 'amount':
          result = ''
          let index = 0
          for (let key in statData[statBlock.key]) {
            result += `${this.CurrencySymbol[key.toUpperCase()]}${Number(
              statData[statBlock.key][key]
            ).toLocaleString()}`
            if (index < Object.keys(statData[statBlock.key]).length - 1) {
              result += '<br />'
            }
            index++
          }
          result = `<h1>${result}</h1>`
          break
        case 'total':
          result = `<h1>${statData[statBlock.key]}</h1>`
          break
        case 'users':
          let flds = [
            { key: 'name', label: 'Name' },
            { key: 'total', label: 'Total' },
            { key: 'gbp', label: this.currencySymbol('GBP') },
            { key: 'eur', label: this.currencySymbol('EUR') },
            { key: 'usd', label: this.currencySymbol('USD') }
          ]
          let tbl = []
          statData[statBlock.key].forEach((user) => {
            tbl.push({
              uid: user.uid,
              name: user.fullName,
              total: user.total,
              gbp: user.amount?.gbp
                ? this.currencySymbol('GBP') +
                  parseFloat(Number(user.amount.gbp).toLocaleString()).toFixed(
                    2
                  )
                : '',
              eur: user.amount?.eur
                ? this.currencySymbol('EUR') +
                  parseFloat(Number(user.amount.eur).toLocaleString()).toFixed(
                    2
                  )
                : '',
              usd: user.amount?.usd
                ? this.currencySymbol('USD') +
                  parseFloat(Number(user.amount.usd).toLocaleString()).toFixed(
                    2
                  )
                : ''
            })
          })
          // sort by name
          tbl.sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
          result = {
            items: tbl,
            fields: flds
          }
          break
        case 'projects':
          let pflds = [
            { key: 'title', label: 'Title' },
            { key: 'total', label: 'Total' },
            { key: 'gbp', label: this.currencySymbol('GBP') },
            { key: 'eur', label: this.currencySymbol('EUR') },
            { key: 'usd', label: this.currencySymbol('USD') }
          ]
          let ptbl = []
          statData[statBlock.key].forEach((project) => {
            ptbl.push({
              uid: project.uid,
              title: project.title,
              total: project.total,
              color: project.color,
              gbp: project.amount?.gbp
                ? this.currencySymbol('GBP') +
                  parseFloat(
                    Number(project.amount.gbp).toLocaleString()
                  ).toFixed(2)
                : '',
              eur: project.amount?.eur
                ? this.currencySymbol('EUR') +
                  parseFloat(
                    Number(project.amount.eur).toLocaleString()
                  ).toFixed(2)
                : '',
              usd: project.amount?.usd
                ? this.currencySymbol('USD') +
                  parseFloat(
                    Number(project.amount.usd).toLocaleString()
                  ).toFixed(2)
                : ''
            })
          })
          // sort by name
          ptbl.sort((a, b) => {
            return a.title.localeCompare(b.title)
          })
          result = {
            items: ptbl,
            fields: pflds
          }
          break
        case 'categories':
          let cflds = [
            { key: 'label', label: 'Label' },
            { key: 'total', label: 'Total' },
            { key: 'gbp', label: this.currencySymbol('GBP') },
            { key: 'eur', label: this.currencySymbol('EUR') },
            { key: 'usd', label: this.currencySymbol('USD') }
          ]
          let ctbl = []
          statData[statBlock.key].forEach((category) => {
            ctbl.push({
              label: category.label,
              total: category.total,
              gbp: category.amount?.gbp
                ? this.currencySymbol('GBP') +
                  parseFloat(
                    Number(category.amount.gbp).toLocaleString()
                  ).toFixed(2)
                : '',
              eur: category.amount?.eur
                ? this.currencySymbol('EUR') +
                  parseFloat(
                    Number(category.amount.eur).toLocaleString()
                  ).toFixed(2)
                : '',
              usd: category.amount?.usd
                ? this.currencySymbol('USD') +
                  parseFloat(
                    Number(category.amount.usd).toLocaleString()
                  ).toFixed(2)
                : ''
            })
          })
          // sort by name
          ctbl.sort((a, b) => {
            return a.label.localeCompare(b.label)
          })
          result = {
            items: ctbl,
            fields: cflds
          }
          break
        default:
          result = '<h4>-</h4>'
      }
      return result
    },
    FilterByStatus(status) {
      this.reportsLabelStatus[status] = !this.reportsLabelStatus[status]
      const el = document.getElementById('report-box-' + status)
      let elClassList = el.classList.value.split(' ')
      let statusList = Object.assign([], this.SelectedExpenseStatus)
      if (statusList.length === 0) {
        statusList = ['PENDING', 'APPROVED', 'REJECTED', 'PAID']
        this.$store.commit(
          'setSelectedExpenseStatus',
          this.expenseStatusOpts.map((itm) => itm.value)
        )
      }
      if (elClassList.includes('disabled')) {
        elClassList = elClassList.filter((cls) => cls !== 'disabled')
      } else {
        elClassList.push('disabled')
      }
      el.className = elClassList.join(' ')
      if (statusList.includes(status)) {
        statusList = statusList.filter((itm) => itm !== status)
      } else {
        statusList.push(status)
      }
      this.$store.commit('setSelectedExpenseStatus', statusList)
    },
    showStatField({ show, key }, statusItem) {
      if (['users', 'projects', 'categories'].includes(key)) {
        return this.reportsData?.[statusItem]?.[key].length ?? false
      } else if (['amount', 'total'].includes(key)) {
        return this.reportsData?.[statusItem]?.[key] ?? 0
      } else {
        return show
      }
    },
    UpdateSearchQuery(q) {
      this.$root.$emit('expenseSearchQueryChanged', q)
    }
  },
  watch: {
    remoteFilters: {
      handler(val) {
        if (typeof val.status === 'undefined') {
          return
        }
        for (const status in this.reportsLabelStatus) {
          this.reportsLabelStatus[status] = val.status.includes(status)
        }
        this.fetchReports(val)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.report-box-value caption {
  color: #fff !important;
  margin: 0 !important;
}
.lizard-report-box {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.lizard-report-box .label {
  background-color: rgba(255, 255, 255, 0.4) !important;
  color: #666 !important;
  font-size: 10pt !important;
  font-weight: 0 !important;
}
</style>

<style scoped>
.report-box {
  height: 200px;
  padding: 10px;
  font-size: 11pt;
  overflow: hidden !important;
}
.report-box.disabled {
  color: #ccc !important;
  background-color: #eee !important;
}
.report-box ul {
  list-style: none;
  padding: 0;
}
.report-box ul li {
  display: inline-block;
  padding: 0 6px;
}
.report-box-pending {
  background: rgba(255, 194, 61, 0.9);
  color: #333;
  border-radius: 6px 0 0 6px !important;
}
.report-box-approved {
  background: rgba(26, 162, 184, 0.9);
  color: #fff;
}
.report-box-rejected {
  background: rgba(220, 59, 69, 0.9);
  color: #fff;
}
.report-box-paid {
  background: rgba(74, 167, 70, 0.9);
  color: #fff;
  border-radius: 0 6px 6px 0 !important;
}
.report-box-value {
  max-height: 140px;
  overflow-y: auto;
}
.report-box-value::-webkit-scrollbar {
  display: none;
}
.report-stat-btn {
  opacity: 0.4;
}
.report-stat-btn:hover {
  opacity: 0.6;
  cursor: pointer;
}
.report-stat-btn.selected {
  opacity: 1;
}
.txt {
  color: #999;
  text-transform: uppercase;
  font-size: 10pt !important;
}
</style>
