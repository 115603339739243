<script>
exports.ActivitiesService = (api, handleError) => {
  const basePath = '/activities'
  const adminPath = `/admin${basePath}`

  const getList = async (query = '', page = 1, perPage = 10) => {
    try {
      return await api.get(
        `${adminPath}/list?q=${query}&page=${page}&limit=${perPage}`
      )
    } catch (error) {
      return handleError(error)
    }
  }

  return {
    Admin: {
      getList
    }
  }
}
</script>
