<script>
exports.UsersService = (api, handleError) => {
  const basePath = '/users'
  const adminPath = `/admin${basePath}`

  const RESPONSECACHE = {}

  const authenticateUser = async (data) => {
    try {
      return await api.post(`${basePath}/auth`, data)
    } catch (error) {
      return handleError(error)
    }
  }

  const fetchCurrentUser = async () => {
    try {
      return await api.get(`${basePath}/me`)
    } catch (error) {
      return handleError(error)
    }
  }

  const getUsersByRole = async (role = null) => {
    try {
      return await api.get(`${basePath}/roles/${role}`)
    } catch (error) {
      return handleError(error)
    }
  }

  const signOut = async () => {
    try {
      return await api.post(`${basePath}/signout`)
    } catch (error) {
      return handleError(error)
    }
  }

  const createUser = async ({ firstName, lastName, email, role }) => {
    try {
      return await api.post(adminPath, {
        firstName,
        lastName,
        email,
        role
      })
    } catch (error) {
      return handleError(error)
    }
  }

  const getUsersForAdmin = async (role = null) => {
    try {
      return await api.get(`${adminPath}/roles/${role}`)
    } catch (error) {
      return handleError(error)
    }
  }

  const getList = async (settings = {}) => {
    try {
      if (Object.keys(RESPONSECACHE).includes('userslist') && settings.cache) {
        return RESPONSECACHE['userslist']
      }
      const req = await api.get(`${adminPath}/list`)
      if (settings.cache) {
        RESPONSECACHE['userslist'] = req
      }
      return req
    } catch (error) {
      return handleError(error)
    }
  }

  const getRolesList = async () => {
    try {
      return await api.get(`${adminPath}/roles-list`)
    } catch (error) {
      return handleError(error)
    }
  }

  const getReports = async ({
    projectUid,
    userUid,
    from = '2019-08-03',
    to = Date.now()
  }) => {
    try {
      const queryParams = []
      if (projectUid) {
        queryParams.push(`project=${projectUid}`)
      }
      if (userUid) {
        queryParams.push(`user=${userUid}`)
      }
      const queryString = queryParams.length ? '?' + queryParams.join('&') : ''
      return await api.get(`${adminPath}/reports/${from}/${to}${queryString}`)
    } catch (error) {
      return handleError(error)
    }
  }

  const updateUser = async ({ uid, data }) => {
    try {
      const toUpdate = {}
      for (let key in data) {
        switch (key) {
          case 'role':
          case 'birthday':
          case 'active':
          case 'staffing':
          case 'leaveAllowances':
            toUpdate[key] = data[key]
            break
        }
      }
      return await api.patch(`${adminPath}/uid/${uid}`, toUpdate)
    } catch (error) {
      console.log(error)
      return handleError(error)
    }
  }

  const getNotificationInfo = async (notifUID) => {
    try {
      return await api.get(`${basePath}/notifications/uid/${notifUID}`)
    } catch (error) {
      return handleError(error)
    }
  }

  const markNotificationAsRead = async (notifUID) => {
    try {
      return await api.patch(`${basePath}/notifications/uid/${notifUID}`, {
        status: 'READ'
      })
    } catch (error) {
      return handleError(error)
    }
  }

  return {
    authenticateUser,
    fetchCurrentUser,
    getUsersByRole,
    getNotificationInfo,
    markNotificationAsRead,
    signOut,
    Admin: {
      createUser,
      getUsersForAdmin,
      getList,
      getRolesList,
      getReports,
      updateUser
    }
  }
}
</script>
