<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-12 text-left" id="ExpensesFilters">
        <div class="row">
          <div class="col-lg text-left txt">filters:</div>
          <div class="col-lg-2 text-right">
            <b-button size="sm" variant="outline-warning" @click="resetFilters"
              >reset filters</b-button
            >
          </div>
        </div>
        <div class="row" style="margin: 0 !important; padding: 0 !important">
          <div class="col-lg-12 text-left">
            <div class="row">
              <div class="col-lg-12 text-left filter-col">
                <ul>
                  <li style="max-width: 20% !important">
                    <treeselect
                      v-if="teamMembersTreeSettings.options.length"
                      class="team-members-select"
                      :value="teamMembersTreeSettings.value"
                      :multiple="teamMembersTreeSettings.multiple"
                      :clearable="teamMembersTreeSettings.clearable"
                      :searchable="teamMembersTreeSettings.searchable"
                      :open-on-click="teamMembersTreeSettings.openOnClick"
                      :close-on-select="teamMembersTreeSettings.closeOnSelect"
                      :limit="teamMembersTreeSettings.limit"
                      :options="teamMembersTreeSettings.options"
                      @select="userChanged"
                    />
                    <div class="d-flex justify-content-center mb-3" v-else>
                      <b-button size="sm" variant="light" disabled>
                        <b-spinner
                          small
                          type="grow"
                          label="Loading..."
                        ></b-spinner>
                        &nbsp;<strong style="color: #666"
                          >Loading Team Members...</strong
                        >
                      </b-button>
                    </div>
                  </li>
                  <li style="max-width: 20% !important">
                    <treeselect
                      v-if="projectsTreeSettings.options.length"
                      class="team-members-select"
                      :value="projectsTreeSettings.value"
                      :multiple="projectsTreeSettings.multiple"
                      :clearable="projectsTreeSettings.clearable"
                      :searchable="projectsTreeSettings.searchable"
                      :open-on-click="projectsTreeSettings.openOnClick"
                      :close-on-select="projectsTreeSettings.closeOnSelect"
                      :limit="projectsTreeSettings.limit"
                      :options="projectsTreeSettings.options"
                      @select="projectChanged"
                    />
                    <div class="d-flex justify-content-center mb-3" v-else>
                      <b-button size="sm" variant="light" disabled>
                        <b-spinner
                          small
                          type="grow"
                          label="Loading..."
                        ></b-spinner>
                        &nbsp;<strong style="color: #666"
                          >Loading Project List...</strong
                        >
                      </b-button>
                    </div>
                  </li>
                  <li style="max-width: 20% !important">
                    <treeselect
                      v-if="categoriesTreeSettings.options.length"
                      class="team-members-select"
                      :value="categoriesTreeSettings.value"
                      :multiple="categoriesTreeSettings.multiple"
                      :clearable="categoriesTreeSettings.clearable"
                      :searchable="categoriesTreeSettings.searchable"
                      :open-on-click="categoriesTreeSettings.openOnClick"
                      :close-on-select="categoriesTreeSettings.closeOnSelect"
                      :limit="categoriesTreeSettings.limit"
                      :options="categoriesTreeSettings.options"
                      @select="categoryChanged"
                    />
                    <div class="d-flex justify-content-center mb-3" v-else>
                      <b-button size="sm" variant="light" disabled>
                        <b-spinner
                          small
                          type="grow"
                          label="Loading..."
                        ></b-spinner>
                        &nbsp;<strong style="color: #666"
                          >Loading Categories List...</strong
                        >
                      </b-button>
                    </div>
                  </li>
                  <li style="min-width: 40% !important">&nbsp;</li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-left filter-col">
                <ul>
                  <li style="max-width: 30% !important">
                    <b-form-radio-group
                      class="allowance-year-group"
                      v-model="DateRange.dateLabel"
                      :options="rangeEnums.dateLabel"
                      @change="dateLabelChanged"
                      button-variant="outline-secondary"
                      name="allowance-years"
                      buttons
                    ></b-form-radio-group>
                  </li>
                  <li style="max-width: 10% !important">
                    <b-form-radio-group
                      class="allowance-year-group"
                      v-model="DateRange.year"
                      :options="generateYears()"
                      @change="yearChanged"
                      button-variant="outline-secondary"
                      name="allowance-years"
                      buttons
                    ></b-form-radio-group>
                  </li>
                  <li>
                    <b-form-checkbox-group
                      class="allowance-year-group"
                      v-model="DateRange.months"
                      :disabled="!DateRange.year"
                      :options="generateMonths()"
                      @change="monthChanged"
                      button-variant="outline-secondary"
                      name="allowance-years"
                      buttons
                    ></b-form-checkbox-group>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import { differenceInQuarters, format as dateFormat } from 'date-fns'

export default {
  name: 'FiltersBar',
  props: {
    rangeEnums: {
      type: Object,
      required: true
    },
    remoteFilters: {
      type: Object,
      default: {}
    },
    teamMembers: {
      type: Array,
      default: () => []
    },
    projectList: {
      type: Array,
      default: () => []
    },
    categoriesList: {
      type: Array,
      default: () => []
    },
    userChanged: {
      type: Function,
      default: () => {}
    },
    projectChanged: {
      type: Function,
      default: () => {}
    },
    categoryChanged: {
      type: Function,
      default: () => {}
    },
    resetFilters: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    Treeselect
  },
  data() {
    return {
      teamMembersTreeSettings: {
        value: 'all',
        multiple: false,
        clearable: false,
        searchable: true,
        openOnClick: true,
        closeOnSelect: true,
        limit: 1,
        options: []
      },
      projectsTreeSettings: {
        value: 'all',
        multiple: false,
        clearable: false,
        searchable: true,
        openOnClick: true,
        closeOnSelect: true,
        limit: 1,
        options: []
      },
      categoriesTreeSettings: {
        value: 'all',
        multiple: false,
        clearable: false,
        searchable: true,
        openOnClick: true,
        closeOnSelect: true,
        limit: 1,
        options: []
      }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    DateRange() {
      return this.Store.selectedExpenseDateRange
    }
  },
  mounted() {
    // const now = new Date()
    // const currentYear = this.DateRange?.year ?? now.getFullYear()
    // this.commitToStore('setSelectedExpenseDateRange', {
    //   year: currentYear
    // })
    // this.monthChanged()
    // this.dateLabelChanged(this.dateLabels[0])
  },
  methods: {
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    },
    generateYears() {
      const now = new Date()
      const currentYear = now.getFullYear()
      const sideYear =
        currentYear +
        (!Math.abs(
          differenceInQuarters(now, new Date(currentYear - 1, 1, 1, 0, 0, 0, 0))
        )
          ? 0
          : -1)
      return [sideYear, currentYear]
    },
    generateMonths() {
      const year = this.DateRange?.year ?? this.filters?.year ?? null
      if (!year) {
        return []
      }
      const now = new Date()
      const currentYear = now.getFullYear()
      const totalMonthsInYear = year == currentYear ? now.getMonth() + 1 : 12
      return Array.from({ length: totalMonthsInYear }, (v, k) => k + 1).map(
        (month) => {
          return dateFormat(new Date(year, month - 1, 1), 'MMMM')
        }
      )
    },
    dateLabelChanged(dateLabel) {
      this.commitToStore('setSelectedExpenseDateRange', {
        dateLabel
      })
    },
    yearChanged(year) {
      this.commitToStore('setSelectedExpenseDateRange', {
        year
      })
    },
    monthChanged(months = null) {
      if (!months) {
        months = this.generateMonths().reverse().slice(0, 3).reverse()
      }
      this.commitToStore('setSelectedExpenseDateRange', {
        months
      })
    }
  },
  watch: {
    remoteFilters: {
      handler(val) {
        const filterKeys = Object.keys(val)
        if (!filterKeys.length) {
          return
        }
        this.teamMembersTreeSettings.value = val?.user ?? 'all'
        this.projectsTreeSettings.value = val?.project ?? 'all'
        this.categoriesTreeSettings.value = val?.category ?? 'all'
        this.commitToStore('setSelectedExpenseDateRange', val)
      },
      immediate: true,
      deep: true
    },
    teamMembers: {
      handler(list = []) {
        this.teamMembersTreeSettings.options = list
      },
      immediate: true,
      deep: true
    },
    projectList: {
      handler(list = []) {
        this.projectsTreeSettings.options = list
      },
      immediate: true,
      deep: true
    },
    categoriesList: {
      handler(list = []) {
        this.categoriesTreeSettings.options = list
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style>
#status-group label {
  background-color: #eee !important;
  color: #ccc !important;
}
#status-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#status-group label.active:first-child {
  background-color: #ffc23d !important;
  color: #fff !important;
}
#status-group label.active:nth-child(2) {
  background-color: #1aa2b8 !important;
  color: #fff !important;
}
#status-group label.active:nth-child(3) {
  background-color: #dc3b45 !important;
  color: #fff !important;
}
#status-group label.active:last-child {
  background-color: #4aa746 !important;
  color: #fff !important;
}
.filters-text {
  font-size: 0.9rem;
  color: #ccc;
  font-weight: 600;
  font-variant: small-caps;
}
.filters-text:hover {
  text-decoration: none;
  color: #999;
}
.filters-text:active {
  color: #333;
}
.filters-text.active {
  color: #999;
}
#ExpensesFilters {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
#ExpensesFilters .allowance-year-group {
  min-width: fit-content !important;
  line-height: 1 !important;
  margin: 0 !important;
}
#ExpensesFilters .allowance-year-group label {
  background-color: #eee !important;
  color: #ccc !important;
  height: 32px !important;
  min-height: 32px !important;
  line-height: 2 !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  font-size: 75% !important;
  padding: auto 0 !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
#ExpensesFilters .allowance-year-group label:hover {
  background-color: #ccc !important;
  color: #eee !important;
}
#ExpensesFilters .allowance-year-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#ExpensesFilters .allowance-year-group label.active {
  /* background-color: #17a2b8 !important; */
  background-color: #66ccff !important;
  color: #fff !important;
}
#ExpensesFilters .team-members-select {
  font-size: 0.9rem !important;
  color: #999 !important;
  font-weight: 500 !important;
}
#ExpensesFilters .filter-col {
  padding: 0 2px !important;
}

#ExpensesFilters .filter-col ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#ExpensesFilters .filter-col ul li {
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  vertical-align: top;
  text-align: left;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  white-space: nowrap !important;
  max-height: 32px !important;
}
</style>

<style scoped>
.txt {
  color: #999;
  text-transform: uppercase;
  font-size: 10pt !important;
}
</style>
